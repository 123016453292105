<template>
  <div>

    <h3>{{ store_evento.dados_evento.evento[0].nome_evento }}</h3>
    
    <form enctype="multipart/form-data" method="post">
      <fieldset>
        <label for="nome-completo">Nome Completo</label>
        <input type="text" :disabled="formulario_processando == true" v-model="c_nomecompleto.valor.value" :class="{'invalido': !c_nomecompleto.validacao.value && (c_nomecompleto.valor.value.length > 0 || c_nomecompleto.modificado.value)}" required maxlength="100">
      </fieldset>

      <fieldset class="two">
        <label for="cpf">CPF</label>
        <input type="text" :disabled="formulario_processando == true" v-model="c_cpf.valor.value" :class="{'invalido': !c_cpf.validacao.value && (c_cpf.valor.value.length > 0 || c_cpf.modificado.value)}" required maxlength="15">
      </fieldset>

      <fieldset class="two">
        <label for="datanascimento">Data de Nascimento</label>
        <input type="text" :disabled="formulario_processando == true" v-model="c_datanascimento.valor.value" :class="{'invalido': !c_datanascimento.validacao.value && (c_datanascimento.valor.value.length > 0 || c_datanascimento.modificado.value)}" required maxlength="14">
      </fieldset>

      <fieldset class="two">
        <label for="telefone">Telefone</label>
        <input type="text" :disabled="formulario_processando == true" v-model="c_telefone.valor.value" :class="{'invalido': !c_telefone.validacao.value && (c_telefone.valor.value.length > 0 || c_telefone.modificado.value)}" required maxlength="15">
      </fieldset>

      <fieldset class="two">
        <label for="cidades">Cidade</label>
        <select :disabled="formulario_processando == true" v-model="c_cidade.valor.value" :class="{'invalido' : !c_cidade.validacao.value && c_cidade.modificado.value}">
          <option value="0" disabled selected>Selecionar</option>
          <option v-for="cid in store_evento.dados_evento.cidades" :key="cid.id" :value="cid.id">{{ cid.nome_cidade }}</option>
        </select>
      </fieldset>

      <fieldset class="two">
        <label for="igrejas">Igreja</label>
        <select :disabled="formulario_processando == true" v-model="c_igreja.valor.value" :class="{'invalido' : !c_igreja.validacao.value && c_igreja.modificado.value}">
          <option value="0" disabled selected>Selecionar</option>
          <option v-for="igr in store_evento.dados_evento.igrejas.filter(v => v.cidade == c_cidade.valor.value)" :value="igr.id" :key="igr.id">{{ igr.nome }}</option>
        </select>
      </fieldset>

      <fieldset class="two">
        <label for="transporte">Transporte</label>
        <select :disabled="formulario_processando == true" v-model="c_transporte.valor.value" :class="{'invalido' : !c_transporte.validacao.value && c_transporte.modificado.value}">
          <option value="0" disabled selected>Selecionar</option>
          <option :value="trans" v-for="trans in (Object.keys(JSON.parse(store_evento.dados_evento.evento[0].transportes_permitidos)))" :key="trans" >{{ trans == "onibus" ? "Ônibus do Evento" : "Carro Próprio" }}</option>
        </select>
      </fieldset>

      <fieldset class="two">
        <label for="beneficio">Tenho benefício</label>
        <select :disabled="formulario_processando == true" v-model="c_beneficio.valor.value">
          <option :value="key" v-for="(valor, key) in beneficios()" :key="key" :selected="key == 'nenhum'">{{ valor }}</option>
        </select>
      </fieldset>

      <fieldset class="two">
        <label for="forma-pagamento">Forma de pagamento</label>
        <select :disabled="formulario_processando == true" v-model="c_formapagamento.valor.value" :class="{'invalido' : !c_formapagamento.validacao.value && c_formapagamento.modificado.value}">
          <option value="0" disabled selected>Selecionar</option>
          <option :value="pg" v-for="pg in JSON.parse(store_evento.dados_evento.evento[0].tipos_pagamentos_permitidos)" :key="pg">{{ formas_pagamento[pg] }}</option>
        </select>
      </fieldset>

      <fieldset>
        <label for="observacoes">Observações</label>
        <input :disabled="formulario_processando == true" maxlength="1024" type="text" placeholder="Caso tenha alguma observação na sua inscrição!" v-model="c_observacoes.valor.value" />
      </fieldset>

      <fieldset>
        <span class="msg_erro" v-show="!c_nomecompleto.validacao.value && formulario_enviado.value">Preencha seu Nome Completo.</span>
        <span class="msg_erro" v-show="!c_cpf.validacao.value && formulario_enviado.value">Preencha seu CPF corretamente.</span>
        <span class="msg_erro" v-show="!c_datanascimento.validacao.value && formulario_enviado.value">Preencha sua Data de Nascimento corretamente.</span>
        <span class="msg_erro" v-show="!c_telefone.validacao.value && formulario_enviado.value">Preencha um Número de Telefone correto.</span>
        <span class="msg_erro" v-show="!c_cidade.validacao.value && formulario_enviado.value">Selecione sua cidade.</span>
        <span class="msg_erro" v-show="!c_igreja.validacao.value && formulario_enviado.value">Selecione sua Igreja.</span>
        <span class="msg_erro" v-show="!c_transporte.validacao.value && formulario_enviado.value">Selecione seu Transporte.</span>
        <span class="msg_erro" v-show="!c_formapagamento.validacao.value && formulario_enviado.value">Selecione sua Forma de Pagamento.</span>
      </fieldset>
    </form>

    <button :disabled="formulario_validado() == false || formulario_processando == true" v-html="mensagem_botao()" @click="inscrever_evento()"></button>
    <button v-show="formulario_processando == false" :disabled="formulario_processando == true" @click="store_evento.dados_evento.value = {}; store_evento.evento_id = -1;" class="btn-voltar">📝 Escolher outro Evento</button>
  </div>
</template>

<script>
  import { SEvento } from '@/stores/SEvento';
  import { ref, watch } from 'vue';
  import validacoes from  '@/js/validacoes.js';

  export default {
    name : "CBoxFormulario",
    setup(){
      const store_evento = SEvento();
      const formulario_enviado = ref(false);
      const formulario_processando = ref(false);
      const c_nomecompleto = { valor : ref(''), validacao : ref(false), modificado : ref(false) };
      const c_cpf = { valor : ref(''), validacao : ref(false), modificado : ref(false) };
      const c_telefone = { valor : ref(''), validacao : ref(false), modificado : ref(false) };
      const c_datanascimento = { valor : ref(''), validacao : ref(false), modificado : ref(false), idade : 0, timestamp : 0 };
      const c_observacoes = { valor : ref(''), validacao : ref(true), modificado : ref(false) };
      const c_cidade = { valor : ref(0), validacao : ref(false), modificado : ref(false) };
      const c_igreja = { valor : ref(0), validacao : ref(false), modificado : ref(false) };
      const c_transporte = { valor : ref(0), validacao : ref(false), modificado : ref(false) };
      const c_beneficio = { valor : ref('nenhum'), validacao : ref(true), modificado : ref(false) };
      const c_formapagamento = { valor : ref(0), validacao : ref(false), modificado : ref(false) };

      // modifica as refs para poder validar
      watch(c_nomecompleto.valor, (newValue) => {
        c_nomecompleto.modificado.value = true;
        c_nomecompleto.validacao.value = (newValue.length >= 5);
      });

      watch(c_cpf.valor, (newValue) => {
        const validacao = validacoes.mascaraCPF(newValue);
        c_cpf.valor.value = validacao[0];
        c_cpf.validacao.value = !validacao[1];
        c_cpf.modificado.value = true;
      });

      watch(c_datanascimento.valor, (newValue) => {
        const validacao = validacoes.mascaraDataNascimento(newValue);
        const data = validacoes.validaDataNascimento(newValue);
        const idade_pessoa = (data != false) ? Math.floor((Date.now() - Number( data.getTime() )) / 1000 / 60 / 60 / 24 / 365) : 0;
        c_datanascimento.validacao.value = !validacao[1];
        c_datanascimento.valor.value = validacao[0];
        c_datanascimento.modificado.value = true;
        c_datanascimento.idade = idade_pessoa;
        c_datanascimento.timestamp = (data != false) ? data.getTime() : 0;
      });

      watch(c_telefone.valor, (newValue) => {
        const validacao = validacoes.mascaraTelefone(newValue);
        c_telefone.validacao.value = !validacao[1];
        c_telefone.valor.value = validacao[0];
        c_telefone.modificado.value = true;
      });

      watch(c_cidade.valor, () => {
        c_igreja.valor.value = 0;
        c_cidade.modificado.value = true;
        c_cidade.validacao.value = c_cidade.valor.value > 0;
      });

      watch(c_igreja.valor, () => {
        c_igreja.modificado.value = true;
        c_igreja.validacao.value = c_igreja.valor.value > 0;
      });

      watch(c_transporte.valor, () => {
        c_transporte.modificado.value = true;
        c_transporte.validacao.value = c_transporte.valor.value != '0';
      });

      watch(c_formapagamento.valor, () => {
        c_formapagamento.modificado.value = true;
        c_formapagamento.validacao.value = c_formapagamento.valor.value != '0';
      });

      const formas_pagamento = {
        "pix" : "Pix",
        "dinheiro" : "Cartão de Crédito",
        "credito" : "Cartao de Crédito"
      };

      const beneficios = () => {
        const beneficios_values = 'Nenhum,Criança,Adolescente,Desbravador,Aventureiro,Membro Igreja,Autista,Gestante,Idoso,Deficiente Físico'.split(',');
        const beneficios_keys = 'nenhum,criança,adolescente,desbravador,aventureiro,membro_igreja,autista,gestante,idoso,deficiente_físico'.split(',');
        return Object.fromEntries(beneficios_keys.map((valor, chave) => [valor, beneficios_values[chave]] ));
      }

      const mensagem_botao = () => {
        if(formulario_processando.value == true){
          return `Aguarde, estamos fazendo sua inscrição...`;
        }else
          if(!formulario_validado()){
            return `Preencha corretamente o Formulário`;
          }else{
            return `✔️ Fazer minha Inscrição`;
          }
      }

      const inscrever_evento = () => {
        if(formulario_validado() == false || formulario_processando.value == true) return false;
        formulario_enviado.value = true;
        formulario_processando.value = true;

        const dados_envio = new FormData();
        dados_envio.set("datanascimento_timestamp", c_datanascimento.timestamp);
        dados_envio.set("evento-selecionado-id", store_evento.dados_evento.evento[0].token_evento);
        dados_envio.set("evento-selecionado-nome", store_evento.dados_evento.evento[0].nome_evento);
        dados_envio.set("nome-completo", c_nomecompleto.valor.value);
        dados_envio.set("cpf", c_cpf.valor.value);
        dados_envio.set("datanascimento", c_datanascimento.valor.value);
        dados_envio.set("telefone", c_telefone.valor.value);
        dados_envio.set("cidades", c_cidade.valor.value);
        dados_envio.set("igrejas", c_igreja.valor.value);
        dados_envio.set("observacoes", c_observacoes.valor.value);
        dados_envio.set("transporte", c_transporte.valor.value);
        dados_envio.set("beneficio", c_beneficio.valor.value);
        dados_envio.set("forma-pagamento", c_formapagamento.valor.value);
        
        fetch(`https://eventosiasd.contabil.workers.dev/evento/inscricao/${dados_envio.get('evento-selecionado-id')}`, { method : "POST", body : dados_envio })
        .then(async(response) => {
          const dados_json = (await response.json());

          if(response.status == 500 && dados_json.link_mercado_livre == undefined){
            alert(dados_json.msg);
          }else if(dados_json.link_mercado_livre != undefined){
            alert(dados_json.msg);
            window.location.href = dados_json.link_mercado_livre;
          }else{
            alert(dados_json.msg);
          }
        })
        .catch(e => {
          console.error(e);
          alert("Ocorreu um erro, tente novamente mais tarde!");
        }).finally(()=>{
          formulario_processando.value = false;
        });
      }

      const formulario_validado = () => {
        return (c_nomecompleto.validacao.value && c_cpf.validacao.value &&
        c_telefone.validacao.value && c_observacoes.validacao.value &&
        c_cidade.validacao.value && c_igreja.validacao.value && c_transporte.validacao.value && c_beneficio.validacao.value &&
        c_formapagamento.validacao.value && c_datanascimento.validacao.value);
      }

      return {
        mensagem_botao, inscrever_evento, store_evento, beneficios, formulario_validado,

        formas_pagamento,

        formulario_processando,

        formulario_enviado,
        c_nomecompleto,
        c_cpf,
        c_telefone,
        c_datanascimento,
        c_observacoes,
        c_cidade,
        c_igreja,
        c_transporte,
        c_beneficio,
        c_formapagamento
      }
    }
  }
</script>

<style scoped>
  div {
    display: block;
  }
  div h4, div h3 {
    display: block;
    text-align: center;
  }
  div button {
    display: block;
    width: 100%;
    padding: 10px 10px;
    cursor: pointer;
    font-size: 17px;
    border: 1px solid #dadce0;
    outline: none;
    border-radius: 6px;
    color: #ffffff;
    margin: 10px auto;
    background-color: #085988;
  }
  div button:disabled {
    background-color: #5291b6;
    cursor: default;
  }
  div button:active:not(:disabled) {
    background-color: #032538;
    color: rgb(241, 241, 241);
    cursor: pointer;
  }
  div button.btn-voltar{
    width:50%;
    background-color: #546e7d;
  }
  div button.btn-voltar:active {
    background-color: #23333b;
    color: rgb(241, 241, 241);
    cursor: pointer;
  }
  div form fieldset {
    display:inline-block;
    width:100%;
    box-sizing:border-box;
    border:none;
  }
  div form fieldset label {
    display: block;
    width: 100%;
    padding: 3px 0 5px 3px;
    cursor: pointer;
    box-sizing: border-box;
  }
  div form fieldset input {
    display: block;
    width: 100%;
    padding: 10px 10px;
    font-size: 17px;
    outline: none;
    box-sizing: border-box;
  }
  div form fieldset input.invalido {
    background-color: rgb(180, 23, 23);
    color: rgb(228, 228, 228);
  }
  div form fieldset select.invalido {
    background-color: rgb(180, 23, 23);
    color: rgb(228, 228, 228);
  }
  div form fieldset select{
    display: block;
    width: 100%;
    padding: 10px 10px;
    font-size: 17px;
    border: 1px solid #dadce0;
    outline-color: #6189da;
  }
  div form fieldset input:not(#nome_evento), div form fieldset select:not(#nome_evento) {
    border: none;
    border-bottom: 2px solid #f7f7f7;
    border-radius: 4px;
    box-shadow: 3px 3px 6px -2px #353535;
  }
  div form fieldset input:focus:not(#nome_evento), div form fieldset select:focus:not(#nome_evento) {
    border: none;
    border-bottom: 2px solid #1772bb;
    border-radius: 4px;
    outline: none;
  }
  div form fieldset input#nome_evento {
    border: none;
    font-weight: bold;
    font-size: 17px;
  }
  div form fieldset span.msg_erro {
    display: block;
    font-size: 14px;
    padding: 4px 2px;
    color:rgb(180, 23, 23);
  }
  @media (min-width: 600px) {
    fieldset.two {
      width:49%;
    }
  }
</style>