<template>
  <div>
    <h4>Escolha um evento para inscrição!</h4>
    
    <select name="selecionar_evento" v-model="evento_escolhido">
      <option value="null">Escolha um evento</option>
      <option :value="i+1" v-for="(evt, i) in store_evento.todos_eventos" :key="i">{{ evt.nome_evento }}</option>
    </select>

    <button :disabled="evento_escolhido == 'null'" v-html="mensagem_botao()" @click="inscrever_evento"></button>
  </div>
</template>

<script>
  import { SEvento } from '@/stores/SEvento';
  import { ref } from 'vue';

  export default {
    name : "CBoxEventos",
    setup(){
      const store_evento = SEvento();
      const evento_escolhido = ref("null");

      const mensagem_botao = () => {
        if(evento_escolhido.value == 'null')
          return "Escolha um evento para se inscrever!";
        else
          return `Se inscrever no evento: <strong>${ store_evento.todos_eventos[ evento_escolhido.value - 1 ].nome_evento }</strong>`;
      }

      const inscrever_evento = () => {
        store_evento.escolher_evento( evento_escolhido.value - 1 );
      }

      return {
        evento_escolhido,
        inscrever_evento,
        mensagem_botao,
        store_evento
      }
    }
  }
</script>

<style scoped>
  div {
    display: block;
  }
  div h4 {
    display: block;
    text-align: center;
  }
  div select {
    display: block;
    width: 100%;
    padding: 10px 10px;
    font-size: 17px;
    border: 1px solid #dadce0;
    outline: none;

    border: none;
    border-bottom: 2px solid #f7f7f7;
    border-radius: 4px;
    box-shadow: 3px 3px 6px -2px #353535;
  }

  div button {
    display: block;
    width: 100%;
    padding: 10px 10px;
    cursor: pointer;
    font-size: 17px;
    border: 1px solid #dadce0;
    outline-color: #6189da;
    border-radius: 6px;
    color: #ffffff;
    margin: 10px auto;
    background-color: #085988;
  }

  div button:disabled {
    background-color: #5291b6;
    cursor: default;
  }

  div button:active:not(:disabled) {
    background-color: #032538;
    color: rgb(241, 241, 241);
    cursor: pointer;
  }
</style>